import React from "react";
import Manager from "../components/manager";
import ESafetyComponent from "../components/esafety";

const ESafety = () => {
  return (
    <Manager name="eSafety" title="eSafety">
      <ESafetyComponent/>
    </Manager>
  )
}

export default ESafety;